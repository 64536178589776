/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import o from "../Extent.js";
import r from "../Geometry.js";
import e from "../Multipoint.js";
import i from "../Point.js";
import n from "../Polygon.js";
import t from "../Polyline.js";
function m(o) {
  return void 0 !== o.xmin && void 0 !== o.ymin && void 0 !== o.xmax && void 0 !== o.ymax;
}
function l(o) {
  return void 0 !== o.points;
}
function u(o) {
  return void 0 !== o.x && void 0 !== o.y;
}
function s(o) {
  return void 0 !== o.paths;
}
function y(o) {
  return void 0 !== o.rings;
}
function f(f) {
  return null == f ? null : f instanceof r ? f : u(f) ? i.fromJSON(f) : s(f) ? t.fromJSON(f) : y(f) ? n.fromJSON(f) : l(f) ? e.fromJSON(f) : m(f) ? o.fromJSON(f) : null;
}
function p(o) {
  return o ? u(o) ? "esriGeometryPoint" : s(o) ? "esriGeometryPolyline" : y(o) ? "esriGeometryPolygon" : m(o) ? "esriGeometryEnvelope" : l(o) ? "esriGeometryMultipoint" : null : null;
}
const G = {
  esriGeometryPoint: i,
  esriGeometryPolyline: t,
  esriGeometryPolygon: n,
  esriGeometryEnvelope: o,
  esriGeometryMultipoint: e,
  esriGeometryMultiPatch: n
};
function c(o) {
  return o && G[o] || null;
}
export { f as fromJSON, c as getGeometryType, p as getJsonType, m as isExtent, l as isMultipoint, u as isPoint, y as isPolygon, s as isPolyline };